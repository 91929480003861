<template>
	<Portal to="disclaimers">
		<transition name="fade">
			<section v-show="!loading" class="application-disclosures">
				<div id="disclaimer-container" v-for="disclosure in disclosures">
					<section class="disclaimer">
						{{disclosure.superscript}}{{disclosure.disclosureText}}
					</section>
				</div>
			</section>
		</transition>
	</Portal>
</template>

<script>
	import _cloneDeep from 'lodash.clonedeep';
	import { createToastInterface } from 'vue-toastification';
    import { Portal } from 'portal-vue';

	import showSpinner from '@/mixins/showSpinner';
	import { toastConfig } from '@/utils';

	const toast = createToastInterface(toastConfig);

	export default {
		name: 'ApplicationDisclosures',
		components: {
			Portal
		},
		mixins: [showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			disclosures: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
			};
		},
		async created() {
			try {
				this.showSpinner({ loading: true });

				return this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ submitting: false });
				return toast.error(error);
			}
		},
	};
</script>

<style lang="scss" scoped>
    #disclaimer-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 0.7rem;
        width: 90%;

        .disclaimer {
            margin-top: 1rem;
        }

    }
</style>
